import React from 'react';
import qs from 'qs';

export type Props = {
  vimeoId: string;
  controls?: number;
  portrait?: number;
  byline?: number;
  muted?: number;
  transparent?: number;
  loop?: number;
  autoplay?: number;
};

const defaultVimeoParams = {
  controls: 0,
  portrait: 0,
  byline: 0,
  muted: 1,
  transparent: 0,
  loop: 0,
  autoplay: 0,
};

// NOTE:
// We need to use this component instead of initializing an iframe element by
// using Player class. Because Player class will render iframe with width and
// height attributes, which block us from styling
const EmbeddedVimeo = React.forwardRef<HTMLIFrameElement | null, Props>(({ vimeoId, children, ...rest }, ref) => {
  const vimeoParams = {
    ...defaultVimeoParams,
    ...rest
  };

  return (
    <iframe
      ref={ref}
      data-ss-autoplay={rest.autoplay}
      className="js-vimeo-embed"
      src={`https://player.vimeo.com/video/${vimeoId}?${qs.stringify(vimeoParams)}`}
      frameBorder={0}
      allow="autoplay; picture-in-picture"
      allowFullScreen
    ></iframe>
  );
});

EmbeddedVimeo.displayName = 'EmbeddedVimeo'

export default EmbeddedVimeo;
