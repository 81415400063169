import React, { useRef, useState, useEffect } from 'react';
import VideoCard from 'src/components/VideoCard';
import videoCardStyle from 'src/styles/modules/components/video_card.module.scss';
import cardShuffleStyle from 'src/styles/modules/components/card_shuffle.module.scss';
import { HiOutlineArrowNarrowLeft, HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { range } from 'lodash-es';
import Player from '@vimeo/player';
import useBreakpoints from 'src/hooks/useBreakpoints';
import Link from 'next/link';

type CardPlayer = {
  goNext: () => void;
  goPrev: () => void;
};

type Props = {};

type Video = { id: number | string; message: string; ig: string; url: string }

const videos: Video[] = [
  {
    id: 899454412,
    message:
      'I had no desire to visit Bali - it felt overtouristy ttm. But after coming across @seeksophie, I knew I had to cover it',
    ig: '@shawnekoh',
    url: 'https://www.instagram.com/p/CzbLEUdS5tg',
  },
  {
    id: 899454695,
    message:
      'Living my fearless and boldest Moana moment. Grateful to find unique adventures via @seeksophie',
    ig: '@kahmy.kaye',
    url: 'https://www.instagram.com/p/CkvRytTh36I',
  },
  {
    id: 899454396,
    message: '@seeksophie brings you to places you never thought existed!',
    ig: '@marcus_cchow',
    url: 'https://www.instagram.com/p/CreYM6Hh3Vv',
  },
  {
    id: 899454317,
    message:
      'Stepped out of our comfort zones with @seeksophie ! Try @seeksophie for unique and unorthodox ideas!',
    ig: '@charshiimi',
    url: 'https://www.instagram.com/p/Cxfa0nGv3ui',
  },
  {
    id: 899458574,
    message:
      'had a super amazing time in lombok and bali off the beaten path with @seeksophie. savoured the many opportunities i got to interact with local communities.',
    ig: '@nthy_',
    url: 'https://www.instagram.com/p/CyGN8r1BtzH',
  },
  {
    id: 899454345,
    message:
      'Seek Sophie is not just your usual travel platform, has lots of cool & off-the-beaten activities hosted by local businesses.',
    ig: '@outsidewithoslyn',
    url: 'https://www.instagram.com/p/CmMQHidAAIw',
  },
  {
    id: 899454371,
    message:
      'Came across this incredible experience through @seeksophie and have been recommending it to my friends since!',
    ig: '@kahmy.kaye',
    url: 'https://www.instagram.com/p/CoWzayphbes',
  },
]

const videoIds = videos.map(o => o.id);

const CardShuffle = React.forwardRef<CardPlayer | null, Props>((props, ref) => {
  const { isScreenMd } = useBreakpoints()
  const [currentIndex, setCurrentIndex] = useState(0);
  const numCardToShow = 3;
  const activeIndices = range(currentIndex, currentIndex + numCardToShow).
    map(v => v < 0 ? videoIds.length + v : v >= videoIds.length ? v - videoIds.length : v)
  const currentVideoId = videoIds[activeIndices[0]];
  const currentVideo = videos.find(o => o.id === currentVideoId) as Video
  const [players, setPlayers] = useState<Player[]>([])
  const touchStart = useRef<number | null>(null);
  const touchEnd = useRef<number | null>(null);
  const minSwipeDistance = 50;

  const goPrev = () => {
    setCurrentIndex((prev) => {
      let value = prev - 1
      value = value < 0 && (value + numCardToShow === 0) ? videoIds.length + value : value
      return value
    })
  }

  const goNext = () => {
    setCurrentIndex((prev) => {
      let value = prev + 1
      value = value >= videoIds.length && (value - videoIds.length === numCardToShow - 1) ? 0 : value
      return value
    })
  }

  const onTouchStart = (e: React.TouchEvent) => {
    touchEnd.current = null;
    touchStart.current = e.targetTouches[0].clientX;
  };

  const onTouchMove = (e: React.TouchEvent) => {
    touchEnd.current = e.targetTouches[0].clientX;
  };

  const onTouchEnd = () => {
    if (!touchStart.current || !touchEnd.current) return;
    const distance = touchStart.current - touchEnd.current;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe || isRightSwipe) {
      if (isLeftSwipe) {
        goNext()
      }

      if (isRightSwipe) {
        goPrev()
      }
    }
  };

  useEffect(() => {
    players.map((player) => {
      player.pause()
    })
  }, [currentIndex])

  return (
    <div className="row tw-items-center" onTouchStart={onTouchStart} onTouchEnd={onTouchEnd} onTouchMove={onTouchMove}>
      <div className="col-md-6 tw-order-2 md:tw-order-1">
        <div className="md:tw-w-96">
          <Link href={currentVideo.url}>
            <a className="tw-block tw-text-white tw-font-semibold tw-font-styleSans tw-text-xl md:tw-text-2xl tw-text-center tw-mb-4 md:tw-mb-8">
              “{currentVideo.message}”
            </a>
          </Link>

          <Link href={currentVideo.url}>
            <a className="tw-block tw-font-semibold tw-font-styleSans tw-text-brand-green tw-mb-8 tw-text-center">
              {currentVideo.ig}
            </a>
          </Link>

          <div className="tw-flex tw-items-center tw-w-full tw-justify-center">
            <div className="tw-cursor-pointer" onClick={goPrev}>
              <HiOutlineArrowNarrowLeft size={32} className="tw-text-white" />
            </div>
            <div className="tw-h-10 tw-border-l-2 tw-border-white tw-transform tw-rotate-45 tw-mx-4"></div>
            <div className="tw-cursor-pointer" onClick={goNext}>
              <HiOutlineArrowNarrowRight size={32} className="tw-text-white" />
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 tw-order-1 md:tw-order-2 tw-mb-8 md:tw-mb-0">
        <div className="tw-relative tw-mx-auto md:tw-mx-0" style={{ width: isScreenMd ? '100%' : '300px', height: isScreenMd ? '720px' : '428px' }}>
          {videoIds.map((videoId, index) => {
            const pos = activeIndices.includes(index)
              ? activeIndices.findIndex((v) => v === index)
              : 0;
            const level = numCardToShow - pos - 1;
            return (
              <div
                key={videoId}
                className={`video ${cardShuffleStyle.cardDisplay} ${
                  activeIndices.includes(index) ? cardShuffleStyle.active : ''
                }`}
                style={
                  activeIndices.includes(index)
                    ? {
                        zIndex: level,
                        top: `${32 * level}px`,
                        left: `${30 * pos}px`,
                      }
                    : {}
                }
              >
                <div
                  className={`${videoCardStyle.videoContainer} tw-w-60 md:tw-w-96 tw-border-2 tw-border-brand-green`}
                >
                  <VideoCard vimeoId={videoId} setPlayers={setPlayers} unmuteOnPlay={true} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
});

CardShuffle.displayName = 'CardShuffle';

export default CardShuffle;
