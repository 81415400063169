import useInitialData from './useInitialData';
import { VIMEO_UNSUPPORTED_COUNTRIES } from 'src/lib/constants';

const useIsVimeoSupportedCountry = () => {
  const initialData = useInitialData();

  return !VIMEO_UNSUPPORTED_COUNTRIES.some((name) => name === initialData.currentGeolocation.countryCode);
};

export default useIsVimeoSupportedCountry;
